:root {
    --main-color: #60a5fa;
    --semi-color: #3f5166f1;

    --text-color: #fff;

    --problem-color: #e44f4f;
    --problem-bg-color: #b48c8c;

    --popup-bg: rgba(31, 30, 30, 0.438);
    --park-popup-bg: rgb(31, 30, 30);
}

._visually-hidden {
    opacity: 0;
}

._hidden {
    display: none;
}

._flex {
    display: flex;
}

._grid {
    display: grid;
}

.main-container {
    display: grid;
    block-size: 100vh;
}

.my-button {
    padding: 10px 20px;

    border: 2px solid var(--main-color);
    border-radius: 10px;
    background-color: transparent;

    font-size: inherit;
    color: #fff;

    cursor: pointer;
}

.my-button:hover {
    background-color: var(--semi-color);
}

.my-button._red-button:hover {
    background-color: var(--problem-bg-color);
}

.my-button:active {
    background-color: var(--semi-color);
}

.my-button:focus {
    outline: none;
}

.my-button:focus-visible {
    outline-color: var(--main-color);
}

.my-button._red-button {
    border-color: var(--problem-color);
}

.my-button._red-button:hover {
    border-color: var(--problem-color);
}

.my-button._red-button:active {
    background-color: var(--problem-bg-color);
}

.my-button._red-button:focus {
    outline: none;
}

.my-button._red-button:focus-visible {
    outline-color: var(--problem-color);
}

.my-popup {
    position: fixed;

    inline-size: 100vw;
    block-size: 100vh;

    inset: 0;

    background-color: var(--popup-bg);
}

.main {
    block-size: calc(100vh - 90px);
}

.content {
    display: grid;
    grid-template-rows: min-content max-content min-content;
    gap: 20px;
}

.header {
    display: grid;
    grid-template-columns: min-content max-content min-content;

    justify-content: space-between;

    inline-size: 100%;
    block-size: 50px;
}

.difficulty {
    display: grid;
    gap: 2rem;

    align-self: center;
    justify-self: center;

    inline-size: clamp(23.4375rem, 19.5863rem + 16.4319vw, 34.375rem);

    padding: 1rem;
    border: 2px solid var(--main-color);
    border-radius: 1rem;

    text-align: center;
}

.difficulty__title {
    color: #fff;
}

.button-set {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo {
    inline-size: 110px;
    block-size: 100%;
}

.title {
    color: var(--text-color);
}

.scheme {
    display: grid;
    grid-template-columns: 18% 58% 18%;

    gap: 2%;

    inline-size: 100%;
    block-size: 80%;
}

.park-list {
    display: grid;
    gap: 20px;

    padding: 10px;

    border-left: 2px solid var(--main-color);
}

.parks-scheme {
    justify-self: center;

    padding-inline: 10px;
    padding-block: 10px;
}

.parks-scheme__list {
    position: relative;

    border: 2px solid var(--main-color);
    border-radius: 20px;

    background-image: url(../images/scheme_logo.svg);
    background-position: center;
    background-size: 40%;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
}

.parks-scheme__list-item {
    position: absolute;

    text-align: center;
    color: var(--text-color);
}

.park-mark {
    inline-size: 160px;
    block-size: 160px;

    padding: 10px;

    border: 2px solid var(--main-color);
    background-color: #1b1919;
    border-radius: 50%;

    font-weight: 700;
    font-size: inherit;
    color: var(--text-color);

    cursor: pointer;
}

._first-mark {
    inset-inline-start: 18%;
    inset-block-start: 32%;
}

._second-mark {
    inset-inline-start: 28%;
    inset-block-start: 12%;
}

._third-mark {
    inset-inline-start: 43.5%;
    inset-block-start: 4%;
}

._fourth-mark {
    inset-inline-end: 27%;
    inset-block-start: 12%;
}

._fifth-mark {
    inset-inline-end: 18%;
    inset-block-start: 32%;
}

._sixth-mark {
    inset-inline-start: 20%;
    inset-block-end: 20%;
}

._seventh-mark {
    inset-inline-start: 34%;
    inset-block-end: 3%;
}

._eighth-mark {
    inset-inline-end: 34%;
    inset-block-end: 3%;
}

._ninth-mark {
    inset-inline-end: 20%;
    inset-block-end: 20%;
}

.park-card__button {
    inline-size: 100%;

    padding: 10px 5px;

    border: 2px solid transparent;
    border-radius: 10px;
    background-color: transparent;

    font-size: inherit;
    color: var(--text-color);

    cursor: pointer;
}

.my-button:hover,
.park-card__button:hover {
    border-color: var(--main-color);
}

.my-button:active,
.park-card__button:active {
    background-color: var(--semi-color);
}

.my-button:focus,
.park-card__button:focus {
    outline: none;
}

.my-button:focus-visible,
.park-card__button:focus-visible {
    outline: 2px solid var(--main-color);
}

._problem {
    border-color: var(--problem-color);

    animation: blink 2s infinite linear;
}

._problem.park-card__button:active {
    background-color: var(--problem-bg-color);
}

._problem.park-card__button:hover {
    border-color: var(--problem-color);
}

._problem.park-card__button:focus {
    outline: none;
}

._problem.park-card__button:focus-visible {
    outline-color: var(--problem-color);
}

.park-popup {
    display: grid;
    gap: 20px;

    inline-size: 50%;

    padding: 20px;
    margin: auto;

    border-radius: 20px;
    background-color: var(--park-popup-bg);

    color: var(--text-color);
}

.open-park {
    display: grid;
    gap: 50px;
}

.open-park__header {
    display: grid;
    grid-template-columns: 1fr min-content;
}

.open-park__title {
    text-align: center;
}

.open-park__text-heading {
    margin-block-end: 20px;
}

.open-park__buttons {
    grid-template-columns: repeat(2, max-content);

    justify-content: space-around;
}

.score {
    display: grid;

    inline-size: 100%;
    block-size: 20%;

    align-content: center;
}

input[type=range] {
    -webkit-appearance: none;
    inline-size: 50%;

    margin: auto;

    background: transparent;

    pointer-events: none;
}


input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;

    height: 36px;
    width: 36px;

    margin: -14px;

    border-radius: 50%;

    background-color: #fff;
}

input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;

    height: 36px;
    width: 36px;

    margin: -14px;

    border-radius: 50%;

    background-color: #fff;
}

input[type=range]::-ms-thumb {
    -webkit-appearance: none;

    height: 36px;
    width: 36px;

    margin: -14px;

    border-radius: 50%;

    background-color: #fff;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;

    border-radius: 2px;
    background-color: #fff;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;

    border-radius: 2px;
    background-color: #fff;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;

    border-radius: 2px;
    background-color: #fff;
}

/* Track */

input[type=range].minScoreRange::-moz-range-track {
    background-color: var(--problem-color);
}

input[type=range].minScoreRange::-webkit-slider-runnable-track {
    background-color: var(--problem-color);
}

input[type=range].minScoreRange::-ms-track {
    background-color: var(--problem-color);
}

input[type=range].underCenterScoreRange::-moz-range-track {
    background-color: var(--problem-bg-color);
}

input[type=range].underCenterScoreRange::-webkit-slider-runnable-track {
    background-color: var(--problem-bg-color);
}

input[type=range].underCenterScoreRange::-ms-track {
    background-color: var(--problem-bg-color);
}

input[type=range].aboveCenterScoreRange::-moz-range-track {
    background-color: var(--semi-color);
}

input[type=range].aboveCenterScoreRange::-webkit-slider-runnable-track {
    background-color: var(--semi-color);
}

input[type=range].aboveScoreRange::-ms-track {
    background-color: var(--semi-color);
}

input[type=range].maxScoreRange::-moz-range-track {
    background-color: var(--main-color);
}

input[type=range].maxScoreRange::-webkit-slider-runnable-track {
    background-color: var(--main-color);
}

input[type=range].maxScoreRange::-ms-track {
    background-color: var(--main-color);
}

/* Thumb */
input[type=range]::-moz-range-thumb {
    background-size: cover;
    background-position: center;

    border: none;
}

input[type=range]::-webkit-slider-thumb {
    background-size: cover;
    background-position: center;

    border: none;
}

input[type=range]::-ms-thumb {
    background-size: cover;
    background-position: center;

    border: none;
}


input[type=range].minScoreRange::-moz-range-thumb {
    background-color: var(--problem-color);

    background-image: url(../images/angry.png);
}

input[type=range].minScoreRange::-webkit-slider-thumb {
    background-color: var(--problem-color);

    background-image: url(../images/angry.png);
}

input[type=range].minScoreRange::-ms-thumb {
    background-color: var(--problem-color);

    background-image: url(../images/angry.png);
}

input[type=range].underCenterScoreRange::-moz-range-thumb {
    background-color: var(--problem-bg-color);

    background-image: url(../images/sad.png);
}

input[type=range].underCenterScoreRange::-webkit-slider-thumb {
    background-color: var(--problem-bg-color);

    background-image: url(../images/sad.png);
}

input[type=range].underCenterScoreRange::-ms-thumb {
    background-color: var(--problem-bg-color);

    background-image: url(../images/sad.png);
}

input[type=range].aboveCenterScoreRange::-moz-range-thumb {
    background-color: var(--semi-color);

    background-image: url(../images/cute.png);
}

input[type=range].aboveCenterScoreRange::-webkit-slider-thumb {
    background-color: var(--semi-color);

    background-image: url(../images/cute.png);
}

input[type=range].aboveScoreRange::-ms-thumb {
    background-color: var(--semi-color);

    background-image: url(../images/cute.png);
}

input[type=range].maxScoreRange::-moz-range-thumb {
    background-color: var(--main-color);

    background-image: url(../images/in-love.png);
}

input[type=range].maxScoreRange::-webkit-slider-thumb {
    background-color: var(--main-color);

    background-image: url(../images/in-love.png);
}

input[type=range].maxScoreRange::-ms-thumb {
    background-color: var(--main-color);

    background-image: url(../images/in-love.png);
}

@keyframes blink {
    0% {
        border-color: transparent;
    }

    50% {
        border-color: var(--problem-bg-color);
    }

    100% {
        border-color: var(--problem-color);
    }
}