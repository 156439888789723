*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    block-size: 100%;
}

body {
    margin: 0;
    padding: 0;

    font-size: 20px;

    background-color: #1b1919;
}

h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
blockquote,
fieldset {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
}